import React from 'react'
import './animation.scss'




export interface AnimationProps {
 brandAdverbs?: any;
 stop?: any;
}

export interface AnimationState {
activeIndex: number;
}




class Animation extends React.Component<AnimationProps, AnimationState> {
  constructor(props: AnimationProps) {
    super(props);

    this.state = {
      activeIndex: 1
    };
  }

  play = 0;


  componentDidMount = () => {
    let container: any = document.getElementById("adverbs");
    let adverbs: any = container.getElementsByClassName("adverb");
    adverbs[0].classList.add("active");
    const wordCount: number = adverbs.length;
    const cssDuration: number = 1170;
    const totalDuration: number = wordCount * cssDuration;

    this.animate(totalDuration, adverbs);
  }



  animate = (duration?: number, elements?: any) => {
 this.play = setInterval(() => this.changeWord(elements), duration);
  }

  changeWord = (words: any) => {
    const wordArray = Array.from(words);
    for (var i = 0; i < wordArray.length; i++) {
      if (i === this.state.activeIndex) {
        words[i].classList.add("active");
      } else {
        words[i].classList.remove("active");
      }
    };

    let currentIndex = this.state.activeIndex;
    if (currentIndex < wordArray.length - 1) {
     this.setState((prevState, props) => ({
      activeIndex: prevState.activeIndex + 1
      }));
    } else {
    this.setState({activeIndex: 0})
    }
  }


  componentDidUpdate() {
    let element: any = document.querySelector('main');
    let cardopen: any = element.classList.contains('frozen');

  if (cardopen === true) {
    this.unmountAnimation();
  }

  }

  unmountAnimation() {
    this.props.stop();
  }

  componentWillUnmount = () => {
    clearInterval(this.play);
  console.log("animation unmounted");
  }


render(){

  return(
<div id="animation">

<div className="adverb-inner" id="adverbs">
      {this.props.brandAdverbs.map((word: any, index: number) => { return  <div key={word.label} className="adverb" id={word.label}>
    {word.img}
</div> })}

  </div>
</div>
)
}
}

export default Animation;
