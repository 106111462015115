import React from 'react'
import TableIcon from "components/KPI/TableIcons/TableIcons";
import { ColumnBorder, RacingStripe } from "components/KPI/KpiTable/TableDecorations";
import "./KpiProgressCard.scss";
import { KpiProgressBar } from 'components/KpiProgressBar';

import { LeaderboardOriginal } from 'components/KPI/Leaderboard';

export interface KpiProgressCardProps {
    data?: any;
    title?: any;
    height?: any;
    remainingwidth?: any;
    activewidth?: any;
    style?: object;
}

export interface KpiProgressCardState {

}

class KpiProgressCard extends React.Component<KpiProgressCardProps, KpiProgressCardState> {
    public static defaultProps = {
        data: { title: "" }
    };
    constructor(props: KpiProgressCardProps) {
        super(props);
        this.state = {};
        debugger;
    }
    render() {

        var testData = [
            { name: "Austin", code: "AUS", color: "blue", score: 78 },
            { name: "Dallas", code: "DAL", color: "rebeccapurple", score: 70 },
            { name: "Denver", code: "DEN", color: "gold", score: 68 },
            { name: "Boise", code: "BOI", color: "green", score: 67 },
            { name: "Atlanta", code: "ATL", color: "maroon", score: 65 },
            { name: "Atlanta South", code: "ATS", color: "maroon", score: 65 },
            { name: "Charleston", code: "CHA", color: "white", score: 65 },
            { name: "Cleveland", code: "CLE", color: "skyblue", score: 60 },
            { name: "Melbourne", code: "MEL", color: "green", score: 58 },
            { name: "Boulder", code: "BOU", color: "red", score: 57 },
            { name: "Phoenix", code: "PHX", color: "orange", score: 57 }
        ]

        const performanceColor = {
            up: "#8FC113",
            neutral: "#666666",
            down: "#F25F4A"
        };

        const arrowDirection = {
            up: "up",
            neutral: "neutral",
            down: "down",
        };



        return (
            <div id="progress-card-container">

                <div className="progress-header-wrapper">
                    <ProgressHeader
                        title={this.props.data.kpi.label}

                        percentYOY={`${this.props.data.kpi.yoy}%`}
                        yoyColor={this.props.data.kpi.yoy >= 0 ? performanceColor.up : performanceColor.down}

                        percentTarget={`${this.props.data.kpi.target}%`}
                        targetColor={this.props.data.kpi.target >= 0 ? performanceColor.up : performanceColor.down}
                    />

                </div>

                <KpiProgressBar
                    progress={this.props.data.kpi.currentYear[0].progressPercent}
                    activecolor={'#1FB3E4'}
                    remainingcolor={'#A7E1F4'}
                    leftlabel={`${Math.round(this.props.data.kpi.currentYear[0].currentAmount/1000)}K`}
                    rightlabel={`${Math.round(this.props.data.kpi.currentYear[0].remaining/1000)}K`}
                    className={'kpi-progress-remaining thisyear'}
                    labelbgcolor={'#3D6977'}
                />
                <KpiProgressBar
                    progress={this.props.data.kpi.lastYear[0].progressPercent}
                    activecolor={'#BBBBBB'}
                    remainingcolor={'#434343'}
                    leftlabel={`${Math.round(this.props.data.kpi.lastYear[0].currentAmount/1000)}K`}
                    rightlabel={`${Math.round(this.props.data.kpi.lastYear[0].remaining/1000)}K`}
                    className={'kpi-progress-remaining lastyear'}
                    labelbgcolor={'#727272'}
                />

            </div>
        );
    }
}

export default KpiProgressCard;


const ProgressHeader = (props: any) => {

    const performanceColor = {
        up: "#8FC113",
        neutral: "#666666",
        down: "#F25F4A"
    };

    let headerColor;
    let stripeColor;

    if (props.yoyColor && props.targetColor === performanceColor.up) {
        headerColor = performanceColor.up;
        stripeColor = performanceColor.up;
    } else {
        headerColor = "#ffffff";
        stripeColor = "#666666";
    }

    var racingStripe = {
        boxShadow: "inset -5px 5px 1px" + stripeColor,
    };

    return (
        <>
            <RacingStripe style={racingStripe} />
            <div className="progress-header">
                <h2 style={{ color: headerColor }}>{props.title}</h2>
                <div className="progress-header_summary" style={{ color: props.yoyColor, borderColor: props.yoyColor }}>
                    <TableIcon icon="yoy" color={props.yoyColor} />
                    <TableIcon icon="up" />
                    <span>{props.percentYOY}</span>
                </div>
                <div className="progress-header_summary" style={{ color: props.targetColor, borderColor: props.targetColor }}>
                    <TableIcon icon="target" color={props.targetColor} />
                    <TableIcon icon="up" />
                    <span>{props.percentTarget}</span>
                </div>
            </div>
        </>
    )
};
