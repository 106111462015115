import React from "react";
//@ts-ignore
import ReactSpeedometer from "react-d3-speedometer"
// import GaugeChart from 'react-gauge-chart'
import "./GuageChart.scss"

export interface GuageChartProps {
    height?: any;
}

export interface GuageChartState {
}

class GuageChart extends React.Component<GuageChartProps, GuageChartState> {
    constructor(props: GuageChartProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div id="chart-container" style={{height: this.props.height}}>
                <ReactSpeedometer
                    value={333}
                    needleTransitionDuration={4000}
                    needleTransition="easeElastic"
                    currentValueText="Current Value: #{value}"
                    currentValuePlaceholderStyle="#{value}"
                    textColor={"#fff"}
                    width={250}
                    ringWidth={50}
                />
                {/* <GaugeChart id="gauge-chart4"
                    nrOfLevels={10}
                    arcPadding={0.1}
                    cornerRadius={3}
                    percent={0.6}
                /> */}

            </div>
        );
    }
}

export default GuageChart;