import React from 'react';
import './FormTitle.scss';

export interface FormTitleProps {
    title: string;
    subTitle?: string;   
}
const FormTitle = ({ title, subTitle }: FormTitleProps, props: any) => {

    return (
        <div className="title-container">

        <div className="form-title">
            <h2 className="title">{title}</h2>
        </div>

        { subTitle &&
            <div className="form-subtitle">
                <h2 className="subtitle">{subTitle}</h2>
            </div>
        }
        

        </div>
    )
}

export default FormTitle;