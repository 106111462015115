import React, { useCallback, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { TextField as MaterialTextField } from '@material-ui/core';
import { FormControl } from 'react-reactive-form';
import MaskedInput from 'react-text-mask';
import './TextField.scss';
//@ts-ignore
import { useWindowWidth } from '@react-hook/window-size';
import { green } from '@material-ui/core/colors'
//@ts-ignore
import MaskedFormControl from 'react-bootstrap-maskedinput'


// https://github.com/mui-org/material-ui/issues/13347
// https://stackoverflow.com/questions/50620393/how-to-change-material-ui-input-underline-colour
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiFormLabel: {
      // root: {
      // "&$focused": {
      //   color: 'green',
      // },
      // "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
      //   borderColor: purple[500]
      // }
      // },
      // disabled: {},
      // focused: {},
      // error: {},
      // notchedOutline: {},
      // focused: {}
    }
  }
});

// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//   },
// });

const useStyles: any = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    // root: {
    //   "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
    //     borderColor: purple[500]
    //   }
    // },
    // disabled: {},
    // focused: {},
    // error: {},
    // notchedOutline: {},
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: "#fff",
    },
    textField: {
      marginLeft: theme.spacing(3.2),
      marginRight: theme.spacing(4),
      fontStyle: "italic",
    },
    // notchedOutline: {},
    // focused: {
    //   "& $notchedOutline": {
    //     borderColor: "yellow"
    //   }
    // }
    // underline:{
    //   '&:after': {
    //     borderBottom:'2px solid red',

    //   },
    // },
  }),
);

export interface TextFieldProps {
  label: string;
  formControl?: FormControl;
  keyboardType?: string;
  mask?: string;
  onEnter?(): void;
}

const TextField = ({ label, formControl, keyboardType, mask, onEnter }: TextFieldProps) => {
  const classes = useStyles();

  const [value, setValue] = useState(formControl ? formControl.value : '');
  const [error, setError] = useState(isInvalid(formControl));
  const [errorMessage, setErrorMessage] = useState(getErrorMessage(formControl));
  const [forceShrink, setForceShrink] = useState(formControl && !!formControl.value);

  //TODO Use debouncing
  const onTextChange = useCallback(
    event => {
      if (formControl) {
        setValue(event.target.value);
        formControl.setValue(event.target.value);
        formControl.markAsTouched();

        // Clear if error is gone now
        if (error && !isInvalid(formControl)) {
          setError(isInvalid(formControl));
          setErrorMessage(getErrorMessage(formControl))
        }
      }
    },
    []
  );

  const evaluateErrors = () => {
    if (formControl) {
      formControl.markAsTouched();
      setError(isInvalid(formControl));
      setErrorMessage(getErrorMessage(formControl))
    }
  }

  const onBlur = useCallback(
    event => {
      if (formControl ? !formControl.value : value) {
        setForceShrink(false);
      }
      evaluateErrors();
    },
    []
  );

  const onFocus = useCallback(
    event => {
      setForceShrink(true);
    },
    []
  );

  // useEffect(() => {
  //   function onFormChanges(value: any) {
  //     if (formControl) {
  //       setError(isInvalid(formControl));
  //       setErrorMessage(getErrorMessage(formControl))
  //     }
  //   }

  //   formControl && formControl.valueChanges.subscribe(onFormChanges);

  //   return () => {
  //     formControl && formControl.valueChanges.unsubscribe(onFormChanges);
  //   };
  // }, [formControl]);



  return (
    <form className={classes.container} noValidate autoComplete="on">
    <MuiThemeProvider theme={theme}>
        {/* <div id="forminput"> */}
          <MaterialTextField
            id="standard-basic"
            // name={label}
            autoComplete='off'
            className={classes.textField}
            label={label}
            type={keyboardType}
            error={error}
            helperText={error && errorMessage}
            // fullWidth={true}
            margin="normal"
            value={value}
            InputProps={mask ? {
              inputComponent: TextMaskCustom,

              // classes: classes.root
              // classes: {
              // notchedOutline: classes.notchedOutline,
              // focused: classes.focused
            // }
              style: {fontSize: '1.5em'}
            } :
            {
              style: {fontSize: '1.5em'}
            } as any}
            InputLabelProps={{style: {fontSize: '1.5em'}, shrink: forceShrink}}
            FormHelperTextProps={{style: {fontSize: '1.3em', textAlign: 'right', color:'#CE526D'}}}
            onChange={onTextChange}
            style = {{width: useWindowWidth() > 768 ? '96vw' : '84vw'}}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter' && onEnter) {
                ev.preventDefault();
                evaluateErrors();
                onEnter();

                //@ts-ignore
                if (formControl && formControl._parent.valid) {
                  //@ts-ignore
                  let done = formControl._parent.get("done");
                  if (done) {
                    done.setValue(true);
                  }
                }

              }
            }}
          />
        {/* </div> */}
        </MuiThemeProvider>
    </form>
  );
}

export default TextField;

function isInvalid(formControl?: FormControl): boolean {
  return formControl ? (formControl.touched || formControl.submitted) && !formControl.valid : false;
}

function getErrorMessage(formControl?: FormControl) {

  if (!formControl) return;

  let errors = formControl ? formControl.errors : null;

  if (errors) {
    if (errors.email) {
      return "Please enter valid email address";
    }
    else if (errors.required) {
      return "This field is required.";
    }
    else if (errors.phone) {
      return "Please enter valid phone number";
    }
  }
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    // <MaskedInput
    //   {...other}
    //   ref={(ref: any) => {
    //     inputRef(ref ? ref.inputElement : null);
    //   }}
    //   mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    //   placeholderChar={'\u2000'}
    //   showMask
    // />

          <MaskedFormControl
          {...other}
          label="Phone"
            name={'phone'}
            autoComplete="off"
            mask={'(111) 111-1111'}
            required={true}
            pattern={"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"}
            // onChange={this.bindFieldToValue.bind(this)}
            // onFocus={activateField}
            // onBlur={disableField}
          />
  );
}
