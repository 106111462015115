import React, { memo } from 'react'
import { useSpring, a, config } from 'react-spring'
// import { Home } from 'components/form';
// import { Header } from "components/header"
import './LeaderboardPage.scss'
import { MoveWithForm } from 'screens/moveWithForm';
import { MoveWithCard } from 'components/card/templates/moveWith';
import { FormGroup, FormBuilder, FormControl } from 'react-reactive-form';
import { FormModel, IForm } from 'shared/model/form';
import GuageChart from 'components/guageChart/GuageChart';
import { KPIPage } from '../KPIPage';
import { Leaderboard } from 'components/leaderboard';
import { navigate } from '@reach/router';
import { OpsHeader } from 'components/opsheader';
// import { KpiProgress } from 'components/kpiProgress';
import { KpiProgressCard } from "components/KpiProgressCard"



export interface LeaderboardPageProps {
  form: any;
  fh: any;
  width: number;
  height: number;
  progress?: number;
  onBack?(): void;
  activecolor?: any;
  remainingcolor?: any;
}

//@ts-ignore
class LeaderboardPage extends React.Component<LeaderboardPageProps, any> {
// function MoveWithPage({ form, fh, width, height, onBack }: MoveWithPageProps) {

  // moveWithForm: FormGroup;
  // formModel: IForm;

  constructor(props: LeaderboardPageProps) {
    super(props);
    this.state = {
    };
    // this.moveWithForm = getMoveWithFormGroup();
    // this.formModel = new FormModel(this.props.form.formJSON, this.moveWithForm);
}
 onLeftIconPress() {
  navigate('/');
}

  render() {


    return (
      <a.div>
        <a.figure style={{ backgroundImage: `url(/imgs/${`99.jpg`})`, backgroundColor: '#2C2C30', opacity:0.8,  height: this.props.fh }}>
        <OpsHeader
        progress={undefined}
        leftIcon={'arrow-left'}
        leftText={'Back'}
        // rightIcon={'user'}
        rightText={'December'}
        onLeftIconPress={this.onLeftIconPress}
        // onRightIconPress={onRightIconPress}
        />
        <KpiProgressCard title="Revenue"/>
        <Leaderboard height={this.props.height}/>
        </a.figure>
        <div style={{zIndex: 2000}}>
          {/* page content here */}
        </div>
      </a.div>
    )
  }
}

export default LeaderboardPage;

function phoneNumberValidator(formControl: FormControl) {
  // validate USA phone number
  if (!/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.exec(formControl.value)) {
      return { phone: true }
  } else {
      return null;
  }
}

function getMoveWithFormGroup(lead: any = {}) {

  const { MoveType, MoveSize, MoveDate, FirstName, Email, Telephone } = lead;

  return FormBuilder.group(
      {
      // MoveType: [MoveType, Validators.required],
      // MoveSize: [MoveSize, Validators.required],
      // MoveDate: [MoveDate, Validators.required],
      // FirstName: [FirstName, Validators.required],
      // Email: [Email, Validators.email],
      Telephone: ['', phoneNumberValidator],
      // done: ['']
  }
);
}
