//@ts-ignore
import faker from 'faker'
import { ICard } from 'shared/model';

export const getCard  = (kpi: any): any => {
    return [
            {
            "template": "kpiProgressCard",
            "data": {
                "title": "OPs KPIs",
                "kpi": kpi
            },
            // "page": {
            //     "template": "opsPage",
            //     // "widthMultiplier": 1,
            //     "sharedElement": {
            //         "height": 900
            //     },
            // },
            "width": {
                "mobile": "full",
                "tablet": "full",
                "desktop": "full"
            },
            "heightMultiplier": {
                "mobile": 0.7,
                // "mobile": 1.9,
                "tablet": 0.3,
                "desktop": 0.2
            },
        },
        {
            "template": "kpiLeaderboardCard",
            "data": {
                "title": "OPs KPIs",
                "kpi": kpi
            },
            // "page": {
            //     "template": "opsPage",
            //     // "widthMultiplier": 1,
            //     "sharedElement": {
            //         "height": 900
            //     },
            // },
            "width": {
                "mobile": "full",
                "tablet": "full",
                "desktop": "full"
            },
            "heightMultiplier": {
                "mobile": 1.8,
                // "mobile": 1.9,
                "tablet": 0.7,
                "desktop": 0.6
            },
        }
    ]
}


//@ts-ignore
window.faker = faker

const generateText = () =>
  Array(12)
    .fill(1)
    .map(faker.lorem.paragraphs)
    .join(' ')


export const kpiDetailCards: ICard[] = [
  // {
  //   "category": `cocktail`,
  //   "title": `Tales from the Hart 1`,
  //   "template": "moveWithCard",
  //   "page": {
  //     "template": "form",
  //     "disableDrag": true,
  //     "form": {
  //       "name": "movewith",
  //       "formJSON": moveWithFormFields(JSON.parse(localStorage.getItem('movewith') || '{}')),
  //       "routeOnDone": "/thanks",
  //       "apiAction": saveLead
  //     },
  //     "widthMultiplier": 1,
  //     "sharedElement": {
  //       "height": 0
  //     },
  //   },
  //   "width": {
  //     "mobile": "full",
  //     "tablet": "full",
  //     "desktop": "full"
  //   },
  //   "height": "full",
  //   // "heightMultiplier": {
  //   //   "mobile": 1.3,
  //   //   "tablet": 1,
  //   //   "desktop": 0.5
  //   // },
  //   text: generateText()
  // },
{
    // "img": '01.jpg',
    // "category": `Restaurant 2`,
    "title": `Operational KPIs`,
    "template": "opsCard",
    "page": {
      "template": "opsPage",
      // "template": "KpiDetails",
      // "form": {
      //   "name": "movewith",
      //   "formJSON": sampleFormFields(JSON.parse(localStorage.getItem('movewith') || '{}')),
      //   "routeOnDone": "/thankyou",
      //   "apiAction": saveLead
      // },
      // "widthMultiplier": 1,
      "sharedElement": {
        "height": 900
      },
    },
    "width": {
      "mobile": "full",
      "tablet": "half",
      "desktop": "half"
    },
    "heightMultiplier": {
      "mobile": 1,
      // "mobile": 1.9,
      "tablet": 1,
      "desktop": 1
    },
    text: generateText()
  },
//   {
//     // "img": '05.jpg',
//     // "category": `Restaurant 7`,
//     "title": `Quality KPIs`,
//     "template": "qualityCard",
//     "page": {
//       "template": "KpiDetails",
//       // "form": {
//       //   "name": "movewith",
//       //   "formJSON": sampleFormFields(JSON.parse(localStorage.getItem('movewith') || '{}')),
//       //   "routeOnDone": "/thankyou",
//       //   "apiAction": saveLead
//       // },
//       "widthMultiplier": 1,
//       "sharedElement": {
//         "height": 500
//       },
//     },
//     "width": {
//       "mobile": "full",
//       "tablet": "quarter",
//       "desktop": "half"
//     },
//     "heightMultiplier": {
//       "mobile": 1,
//       "tablet": 1,
//       "desktop": 0.5
//     },
//     text: generateText()
//   },
  // {
  //   "img": '02.jpg',
  //   // "category": `Restaurant 7`,
  //   "title": `Internal Review`,
  //   "width": {
  //     "mobile": "full",
  //     "tablet": "quarter",
  //     "desktop": "quarter"
  //   },
  //   "heightMultiplier": {
  //     "mobile": 1
  //   },
  //   text: generateText()
  // },
  // {
  //   "img": '03.jpg',
  //   // "category": `Restaurant 3`,
  //   // "template": "kpiCard",
  //   "title": `Accident Free`,
  //   "width": {
  //     "mobile": "full",
  //     "tablet": "quarter",
  //     "desktop": "quarter"
  //   },
  //   "heightMultiplier": {
  //     "mobile": 1,
  //     "tablet": 1,
  //     "desktop": 1
  //   },
  //   text: generateText()
  // },
  // {
  //   "img": '02.jpg',
  //   "category": `Restaurant 4`,
  //   "title": `Rick's Café 1`,
  //   "width": {
  //     "mobile": "half",
  //     "tablet": "half",
  //     "desktop": "half"
  //   },
  //   "heightMultiplier": {
  //     "mobile": 1,
  //     // "tablet": 2,
  //     "desktop": 1
  //   },
  //   text: generateText()
  // },
  // {
  //   "category": `Restaurant 5`,
  //   "title": `Form 1`,
  //   "template": "moveWithCard",
  //   "page": {
  //     "template": "form",
  //     "form": {
  //       "name": "movewith",
  //       "formJSON": moveWithFormFields(JSON.parse(localStorage.getItem('movewith') || '{}')),
  //       "routeOnDone": "/thankyou",
  //       "apiAction": saveLead
  //     },
  //     "widthMultiplier": 1,
  //     "sharedElement": {
  //       "height": 0
  //     },
  //   },
  //   "width": {
  //     "mobile": "full",
  //     "tablet": "half",
  //     "desktop": "half"
  //   },
  //   "heightMultiplier": {
  //     "mobile": 1,
  //     "tablet": 1,
  //     "desktop": 1
  //   },
  //   text: generateText()
  // },
  // {
  //   "img": '04.jpg',
  //   "category": `Restaurant 6`,
  //   "title": `Rick's Café 1`,
  //   "width": {
  //     "mobile": "half",
  //     "tablet": "quarter",
  //     "desktop": "quarter"
  //   },
  //   "heightMultiplier": {
  //     "desktop": 2
  //   },
  //   text: generateText()
  // },
  // {
  //   "img": '05.jpg',
  //   "category": `Restaurant 7`,
  //   "title": `Rick's Café 1`,
  //   "width": {
  //     "mobile": "half",
  //     "tablet": "quarter",
  //     "desktop": "quarter"
  //   },
  //   "heightMultiplier": {
  //     "mobile": 2
  //   },
  //   text: generateText()
  // },
  // {
  //   "img": '06.jpg',
  //   "category": `Restaurant 8`,
  //   "title": `Rick's Café 1`,
  //   "width": {
  //     "mobile": "half",
  //     "tablet": "quarter",
  //     "desktop": "quarter"
  //   },
  //   text: generateText()
  // },
  // {
  //   "img": '02.jpg',
  //   "category": `Restaurant 9`,
  //   "title": `Rick's Café 1`,
  //   "width": {
  //     "mobile": "full",
  //     "tablet": "quarter",
  //     "desktop": "quarter"
  //   },
  //   text: generateText()
  // }
];
