import React, { forwardRef, useRef, useState, useCallback } from 'react'
import { useStore } from '../../index'
import './MoveWithForm.scss'
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from 'react-reactive-form';
import { IForm, FormModel } from 'shared/model/form';
import {Form} from 'components/form';

export interface MoveWithFormProps {
    form: any;
    formGroup: FormGroup;
    formModel: IForm;
    width: number;
    path?: string;
    onBack?(): void;
    onLogoTap?(): void;
}

const MoveWithForm = ({ form, formGroup, formModel, width, onBack }: MoveWithFormProps) => {
    // let lastFormData = JSON.parse(localStorage.getItem('movewith') || '{}');

    // const [moveWithForm] = useState(getMoveWithFormGroup());
    // const [formModel] = useState(new FormModel(form.formJSON, moveWithForm));
    // let formModel: IForm = new FormModel(form.formJSON, moveWithForm);
    const [currentIndex, setCurrentIndex] = useState(0);

    // const setup = useCallback(() => {
    //     moveWithForm = getMoveWithFormGroup();
    //     formModel = new FormModel(form.formJSON, moveWithForm);
    // },
    // [form])

    // setup();

    // debugger;
    // const moveWithForm: FormGroup = getMoveWithFormGroup();
    // const formModel: IForm = new FormModel(form.formJSON, moveWithForm);
    // const [currentIndex, setCurrentIndex] = useState(0);

    // debugger;

    const ref = useRef(null) // Our main dom Ref
    const navigate = useStore((state: any) => state.navigate)
    const endNav = useStore((state: any) => state.endNav)

    const onDone = (formValue: any) => {
        // saveLead(formValue);
        form.apiAction(formValue);
        navigate(form.routeOnDone, ref.current);
    }

    const [submitted, setSubmitted] = useState(false);

    const onValueChange = useCallback(value => {
        localStorage.setItem("movewith", JSON.stringify(value));
        // console.log(localStorage.getItem(form.name))
        if (value.done) {
            formGroup.markAsSubmitted();
            formGroup.markAsTouched();
            setSubmitted(true);
        }
        if (value.done && formGroup.valid) {
            onDone(value);
        }
    },
        [currentIndex])

    const onBackNavigation = () => {
        if (onBack) {
            onBack();
        }
    }

    const onFooterTap = () => {
        let formControl: AbstractControl = formGroup.get('MoveDate');
        if (formControl) {
            formControl.setValue('', { emitEvent: false });
        }
    }

    const onLogoTap = () => {
        if (onBack) {
            onBack();
        }
    }

    return (
            <Form
                // ref={ref}
                form={formModel}
                formGroup={formGroup}
                index={0}
                width={width}
                submitted={submitted}
                onValueChange={onValueChange}
                // onIndexChange=
                // {(index: number) => setCurrentIndex(index)
                // }
                onBack={onBackNavigation}
                onFooterTap={onFooterTap}
                onLogoTap={onLogoTap}
            />
    )
};

export default MoveWithForm;

function phoneNumberValidator(formControl: FormControl) {
    // validate USA phone number
    if (!/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.exec(formControl.value)) {
        return { phone: true }
    } else {
        return null;
    }
}


function getMoveWithFormGroup(lead: any = {}) {

    const { MoveType, MoveSize, MoveDate, FirstName, Email, Telephone } = lead;

    return FormBuilder.group(
        {
            // MoveType: [MoveType, Validators.required],
            // MoveSize: [MoveSize, Validators.required],
            // MoveDate: [MoveDate, Validators.required],
            // FirstName: [FirstName, Validators.required],
            // Email: [Email, Validators.email],
            Telephone: ['', phoneNumberValidator],
            // done: ['']
        }
    );
}