import React, { forwardRef } from "react";
import { SingleSelect } from "components/singleSelect";
import { DatePicker } from "components/datePicker";
import { TextField } from "components/textField";
import { Button } from "components/button";

const InputControlSelector = ({ field, ...props }: any) => {

  const selectControl = (fieldType: any) => {
    let view: any;
    switch (fieldType) {
      case 'singleSelect':
        //@ts-ignore
        view = <SingleSelect
          field={field}
          options={field.options}
          formControl={field.formControl}
          bindLabel={field.bindLabel}
          bindValue={field.bindValue}
        />
        break;
      case 'datePicker':
        view = <DatePicker formControl={field.formControl} />
        break;
      case 'textField':
        view = <TextField
            label={field.label}
            formControl={field.formControl}
            keyboardType={field.keyboardType}
            mask={field.mask}
          />
        break;
      case 'button':
        view = <Button
            text={field.label}
            formControl={field.formControl}
             width={'50vw'}
             height={46}
             fontSize={'1.92em'}
             marginTop={'5%'}
          />
        break;
    }
    return view;
  }

  return (
    selectControl(field['fieldType'])
  )
}

export default InputControlSelector;
