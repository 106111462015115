import React from "react";
import './Leaderboard.scss'


export interface LeaderboardProps {
  data?: any;
  height?: any;
}

export interface LeaderboardState {

}

class Leaderboard extends React.Component<LeaderboardProps, LeaderboardState> {
  public static defaultProps = {
    data: { title: "" }
  };

  constructor(props: LeaderboardProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="leaderboard-container">
        <div className="leaderboard-detail">
          <ul>
            <li className="leader-style" style={{ padding: ".25em" }}>
              <div className="checker-flag"></div>
              <div className="lead-heading">Leaderboard</div>
              <div className="checker-flag"></div>
            </li>
            {
              //@ts-ignore
              this.props.data.kpi.leaderBoard.map((obj: any, index: any) => (
                <li key={index} className="leader-style">
                  <span className="lead-rank">{index + 1}</span>
                  <div className="color-obj" style={{ borderColor: obj.color }}></div>
                  <span className="lead-country">{obj.name}</span>
                  <span className="lead-score">{obj.score}</span>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default Leaderboard;