//import React, { Component } from "react";
import * as React from "react";

import "./KpiTable.scss"

const ColumnBorder = (props: any) => {

  const border = {
    yoy: (<div className="column-border yoy"></div>),
    target: (<div className="column-border target"></div>),
  };

    let renderBorder;

    switch (props.column) {
      case "yoy":
        renderBorder = border.yoy;
        break;

      case "target":
        renderBorder = border.target;
        break;

    }
    return <>{renderBorder}</>;
};

export { ColumnBorder }



const RacingStripe = (props: any) => {

  return (
  <div className="racing-stripe" style={props.style}></div>
)

};

export { RacingStripe }
