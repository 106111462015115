import React, { memo } from 'react'
import { useSpring, a, config } from 'react-spring'
// import { Home } from 'components/form';
import { Header } from "components/header"
import './KPIPage.scss'
import { MoveWithForm } from 'screens/moveWithForm';
import { MoveWithCard } from 'components/card/templates/moveWith';
import { FormGroup, FormBuilder, FormControl } from 'react-reactive-form';
import { FormModel, IForm } from 'shared/model/form';


import '../../../sass/index.scss';
import KpiTable from '../../KPI/KpiTable/KpiTable'
import KpiCard from 'components/card/templates/KpiCard';

export interface KPIPageProps {
    form: any;
    fh: any;
    width: number;
    height: number;
    progress?: number;
    onBack?(): void;
    details: any;
    title?: any;
  }

  class KPIPage extends React.Component<KPIPageProps, any> {
    constructor(props: KPIPageProps) {
        super(props);
        this.state = {
        };

  }
  render() {
    return (
      <a.div className="kpi-container">
        <a.figure style={{ backgroundImage: `url(/imgs/${`99.jpg`})`,  height: this.props.fh }}>
          <KpiCard title={this.props.title} />
        </a.figure>
        <div style={{zIndex: 2000}}>

        </div>
      </a.div>
    )
  }
}

export default KPIPage
