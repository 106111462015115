//import React, { Component } from "react";
import * as React from "react";

import "./TableIcons.scss"





export interface TableIconsProps {
    height?: any;
    color?:any;
    icon?:any;
}

export interface TableIconsState {

}

const Card = (props:any) => {
    return <div className="gallery-card">{props.children}</div>;
  };
  export { Card };

class TableIcon extends React.Component<TableIconsProps, TableIconsState> {
    constructor(props: TableIconsProps) {
        super(props);
        this.state = {};
    }

    render() {
        const icon = {
          yoy: (
            <svg
              width="23"
              height="23"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2.04544"
                y="4.13635"
                width="20.9091"
                height="19.8636"
                fill="white"
              />
              <path
                d="M6.1328 16.5028L7.61229 13.2997H9.21385L6.87987 17.831V20.4091H5.39061V17.831L3.05663 13.2997H4.66307L6.1328 16.5028ZM15.5566 17.0155C15.5566 17.7154 15.4329 18.329 15.1855 18.8564C14.9381 19.3837 14.5833 19.7906 14.1211 20.0771C13.6621 20.3635 13.1348 20.5067 12.539 20.5067C11.9499 20.5067 11.4241 20.3651 10.9619 20.0819C10.4997 19.7987 10.1416 19.3951 9.88768 18.871C9.63377 18.3437 9.50519 17.7382 9.50194 17.0546V16.703C9.50194 16.0032 9.62726 15.3879 9.87792 14.8573C10.1318 14.3235 10.4883 13.9149 10.9473 13.6317C11.4095 13.3453 11.9368 13.2021 12.5293 13.2021C13.1217 13.2021 13.6474 13.3453 14.1064 13.6317C14.5687 13.9149 14.9251 14.3235 15.1758 14.8573C15.4297 15.3879 15.5566 16.0015 15.5566 16.6982V17.0155ZM14.0723 16.6933C14.0723 15.9478 13.9388 15.3814 13.6719 14.994C13.4049 14.6067 13.0241 14.413 12.5293 14.413C12.0377 14.413 11.6585 14.6051 11.3916 14.9892C11.1247 15.37 10.9896 15.9299 10.9863 16.6689V17.0155C10.9863 17.7414 11.1198 18.3046 11.3867 18.705C11.6536 19.1054 12.0377 19.3056 12.539 19.3056C13.0306 19.3056 13.4082 19.1135 13.6719 18.7294C13.9355 18.342 14.069 17.7789 14.0723 17.0399V16.6933ZM18.8574 16.5028L20.3369 13.2997H21.9385L19.6045 17.831V20.4091H18.1152V17.831L15.7812 13.2997H17.3877L18.8574 16.5028Z"
                fill="#666666"
              />
              <path
                d="M23.08 3.07H18.25V1.23C18.25 1.1035 18.1465 1 18.02 1H16.41C16.2835 1 16.18 1.1035 16.18 1.23V3.07H8.82V1.23C8.82 1.1035 8.7165 1 8.59 1H6.98C6.8535 1 6.75 1.1035 6.75 1.23V3.07H1.92C1.41112 3.07 1 3.48113 1 3.99V23.08C1 23.5889 1.41112 24 1.92 24H23.08C23.5889 24 24 23.5889 24 23.08V3.99C24 3.48113 23.5889 3.07 23.08 3.07ZM21.93 21.93H3.07V11.005H21.93V21.93ZM3.07 9.05V5.14H6.75V6.52C6.75 6.6465 6.8535 6.75 6.98 6.75H8.59C8.7165 6.75 8.82 6.6465 8.82 6.52V5.14H16.18V6.52C16.18 6.6465 16.2835 6.75 16.41 6.75H18.02C18.1465 6.75 18.25 6.6465 18.25 6.52V5.14H21.93V9.05H3.07Z"
                fill={this.props.color}
              />
            </svg>
          ),

          target: (
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
              <path
                d="M11.5 0C5.175 0 0 5.175 0 11.5C0 17.825 5.175 23 11.5 23C17.825 23 23 17.825 23 11.5C23 5.175 17.825 0 11.5 0ZM11.5 2.875C16.2725 2.875 20.125 6.7275 20.125 11.5C20.125 16.2725 16.2725 20.125 11.5 20.125C6.7275 20.125 2.875 16.2725 2.875 11.5C2.875 6.7275 6.7275 2.875 11.5 2.875ZM11.5 5.75C8.3375 5.75 5.75 8.3375 5.75 11.5C5.75 14.6625 8.3375 17.25 11.5 17.25C14.6625 17.25 17.25 14.6625 17.25 11.5C17.25 8.3375 14.6625 5.75 11.5 5.75ZM11.5 8.625C13.11 8.625 14.375 9.89 14.375 11.5C14.375 13.11 13.11 14.375 11.5 14.375C9.89 14.375 8.625 13.11 8.625 11.5C8.625 9.89 9.89 8.625 11.5 8.625Z"
                fill={this.props.color}
              />
            </svg>
          ),
          up: (<i className="kpi-arrow up" />),
          down: (<i className="kpi-arrow down" />),
          neutral: (<i className="kpi-arrow neutral" />),
        };

        let renderIcon;

        switch (this.props.icon) {
          case "yoy":
            renderIcon = icon.yoy;
            break;

          case "target":
            renderIcon = icon.target;
            break;

            case "up":
            renderIcon = icon.up;
            break;

         case "down":
           renderIcon = icon.down;
           break;

          case "neutral":
            renderIcon = icon.neutral;
            break;
        }

        return <>{renderIcon}</>;
      }
    }

export default TableIcon
