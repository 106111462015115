import React from "react";
import { IOption, IField } from 'shared/model';
import { FormControl } from 'react-reactive-form';
import { Select } from 'components/select';

export interface SingleSelectProps {
  field: IField;
  options: IOption[];
  bindLabel: string;
  bindValue?: string; // binds whole object if not specified
  formControl?: FormControl;
}

export interface SingleSelectState {
  selectedOption: IOption | undefined ;
}

export default class SingleSelect extends React.Component<SingleSelectProps, SingleSelectState> {
  constructor(props: SingleSelectProps) {
    super(props);
    this.handleBubbleSelect = this.handleBubbleSelect.bind(this);
 
    // TODO remove item.selected = true handling for selected item. It will come from fromControl.value now
    // let selectedOption = this.props.formControl ? 
    // (this.props.options.find(option => option.field.bindValue ? option.bindValue === this.props.formControl.value : 
    //   option[option.field.bindLabel] === this.props.formControl.value[option.field.bindLabel] )) 
    //   : 
    //   this.props.options.find(item => item.selected);

    // Selected value is filled at startup from data
    let selectedOption = this.props.field.selected as IOption;

    // Mark the option as selected for UI selection
    if (selectedOption) {
      selectedOption.selected = true;
    }

    this.state = {
      selectedOption: selectedOption
    }
  }

  handleBubbleSelect(option: IOption) {
    let previousSelectedOption: IOption | undefined = this.props.options.find(item => item.selected);

    // toggle options
    // option.selected = !option.selected;

    // always select (don't toggle) 
    option.selected = true;
    // deselect all other
    this.props.options.forEach(item => {
      if (item !== option) {
        item.selected = false;
      }
    });

    let newSelectedOption: IOption | undefined = this.props.options.find(item => item.selected);

    this.setState({ selectedOption: newSelectedOption });

    // Dynamically adds/remove pages. So, execute it first before form update below (which triggers validity check on all form pages **including newly added**).
    if (newSelectedOption && newSelectedOption !== previousSelectedOption) {
      newSelectedOption.addSubPages();
    }
    if (previousSelectedOption && previousSelectedOption !== newSelectedOption) {
      previousSelectedOption.removeSubPages();
    }


    if(this.props.formControl) {
      let option: IOption | undefined = this.props.options.find(item => item.selected);
      if (option) {
        let valueToBind = option.bindValue;
        this.props.formControl.setValue(valueToBind);
      } else {
        this.props.formControl.setValue(null);
      }
      this.props.formControl.markAsDirty();
      this.props.formControl.markAsTouched();
    }

  }

  public render() {
    return (
      <div>
        {this.props.options.map((option: IOption, key) => {
            return (
              <div key={key}>
                <Select 
                  label={option.bindLabel} 
                  icon={option.icon} 
                  selected={option.selected} 
                  onBubbleSelect={() => this.handleBubbleSelect(option)} >

                </Select>
              </div>
            )
          })}
      </div>
    );
  }
}
