import React, { Component } from "react";
import TableIcon from "../TableIcons/TableIcons";
import { ColumnBorder, RacingStripe } from "./TableDecorations";
//@ts-ignore
import "./KpiTable.scss"

export interface KpiTableProps {
  height?: any;
  title?: any;
  kpis?: any[]
}

export interface KpiTableState {
 rowCount: number;
}

class KpiTable extends React.Component<KpiTableProps, KpiTableState> {
  constructor(props: KpiTableProps) {
    super(props);
    this.state = {
      rowCount: 5,

    };
  }

  componentDidMount = () => {
    const kpiArray = this.props.kpis || [];
    const kpiArrayLength: any = kpiArray.length;

    if (this.state.rowCount !== kpiArrayLength) {
      this.setState({rowCount: kpiArrayLength})
    }

    const table: any = document.getElementsByClassName('kpi-table');

      //not finished yet

  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    console.log(prevState.rowCount);
    console.log(this.state.rowCount);
  }



  render() {

    const performanceColor = {
      up: "#8FC113",
      neutral: "#666666",
      down: "#F25F4A"
    };

    const arrowDirection = {
      up: "up",
      neutral: "neutral",
      down: "down",
    };

    var stripeColor = {
      boxShadow: "inset -5px 5px 1px" + performanceColor.neutral
    };

    var gridRows = {
      gridTemplateRows: "auto auto auto auto auto auto"
      //not finished yet
    }



    var gridTemplate = {
      gridTemplateAreas: `'head-label head-yoy head-target spacer"
      "row1-label row1-yoy row1-target spacer"
      "row2-label row2-yoy row2-target spacer"
      "row3-label row3-yoy row3-target spacer"
      "row4-label row4-yoy row4-target spacer"
      "row5-label row5-yoy row5-target spacer'`
      //not finished yet
    }


    return (
      <div className="kpi-table" data-table="kpi" style={gridRows}>

        <RacingStripe style={stripeColor} />

        <TableHeading
          title={this.props.title}
          yoyColor={performanceColor.neutral}
          targetColor={performanceColor.neutral}
        />

        {(this.props.kpis || []).map((kpi, index) => (

          <TableRow key={kpi.row} row={index + 1} label={kpi.label}>

            <Cell
              for={index + 1}
              column="yoy"

              arrow={ parseInt(kpi.yoy)-parseInt(kpi.target)> 0 ?arrowDirection.up:arrowDirection.down}
              color={parseInt(kpi.yoy)-parseInt(kpi.target)> 0 ?performanceColor.up:performanceColor.down}
              percent={kpi.yoy}
            />

            <Cell
              for={index + 1}
              column="target"

              arrow={ parseInt(kpi.yoy)-parseInt(kpi.target)> 0 ?arrowDirection.up:arrowDirection.down}
              color={parseInt(kpi.yoy)-parseInt(kpi.target)> 0 ?performanceColor.up:performanceColor.down}
              percent={kpi.target}
            />

          </TableRow>

        ))}

        <ColumnBorder column="yoy" />
        <ColumnBorder column="target" />
      </div>
    );
  }

}

export default KpiTable




const TableHeading = (props: any) => {

  return (
    <>
      <div data-row="heading" data-column="label" className="kpi-table_title">
        <h2>{props.title}</h2>
      </div>
      <div data-row="heading" data-column="yoy" className="kpi-table_heading-icon">
        <TableIcon icon="yoy" color={props.yoyColor} />
      </div>
      <div data-row="heading" data-column="target" className="kpi-table_heading-icon">
        <TableIcon icon="target" color={props.targetColor} />
      </div>

    </>
  )

};

const TableRow = (props: any) => {
  const handleRowClick = (selectedItem: any) => {
    console.log(selectedItem);
  }
  return (
    <>
      <div data-row={props.row} data-column="label" className="kpi-label" key={props.row} onClick=
        {e => handleRowClick(props)}>
        {props.label}
      </div>
      {props.children}
    </>
  )
};

const Cell = (props: any) => {

  return (

    <div data-row={props.for} data-column={props.column} style={{ color: props.color }}>
      <TableIcon icon={props.arrow} />
      <span>{props.percent}</span>
    </div>

  )

};
