import React, { Component, useState, useEffect, useCallback } from 'react'
import List from 'screens/list/List';
import { kpiCards } from './kpiSummaryCards';
import { navigate } from '@reach/router';




export interface HomeProps {
    path?: string;
}

export interface HomeState {
}

const Home = ({ path }: HomeProps) => {




  var testData = [
    { name: "Austin", code: "AUS", color: "blue", score: 78 },
    { name: "Dallas", code: "DAL", color: "rebeccapurple", score: 70 },
    { name: "Denver", code: "DEN", color: "gold", score: 68 },
    { name: "Boise", code: "BOI", color: "green", score: 67 },
    { name: "Atlanta", code: "ATL", color: "maroon", score: 65 },
    { name: "Atlanta South", code: "ATS", color: "maroon", score: 65 },
    { name: "Charleston", code: "CHA", color: "white", score: 65 },
    { name: "Cleveland", code: "CLE", color: "skyblue", score: 60 },
    { name: "Melbourne", code: "MEL", color: "green", score: 58 },
    { name: "Boulder", code: "BOU", color: "red", score: 57 },
    { name: "Phoenix", code: "PHX", color: "orange", score: 57 }
  ]

  const handleCardClick = useCallback(
    card => {
        //@ts-ignore
        navigate('/leaderboard', { state: {card: card }});
    },
    []
  )


  return (
    <>
      <List cards={kpiCards} path="/" onCardClick={handleCardClick} />
    </>
  )

}

export default Home;
