import React, { useState, useLayoutEffect } from "react";
import { MoveImage } from "components/image";
import { logo } from 'assets/images';
import './Login.scss';
import { TextField } from "components/textField";
import { Button } from "components/button";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { FormControl, FormGroup, Validators, AbstractControl } from "react-reactive-form";
import { useStore } from '../../index';
import { fetchGraphQL } from '../../API/API';
import { validateUser } from '../../API/API';

export interface LoginProps {
    path?: string;
    field?: any;
    onClick?: void;
}

export interface LoginState {

}

const Login = ({ path, field, onClick }: LoginProps) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [loginForm, setLoginForm] = useState(new FormGroup({
        username: new FormControl("", [Validators.required]),
        password: new FormControl("", [Validators.required]),
        done: new FormControl("")
    }));

    const navigate = useStore((state: any) => state.navigate)
    const endNav = useStore((state: any) => state.endNav)
    clearAllBodyScrollLocks();

    useLayoutEffect(() => {

        function onFormChange(value: any) {
            if (loginForm.valid && disabled) {
                setDisabled(false);
            } else if (!loginForm.valid) {
                setDisabled(true);
            }
        }

        loginForm.valueChanges.subscribe(onFormChange);

        return () => {
            loginForm.valueChanges.unsubscribe(onFormChange);
        };
    }, [navigate]);

    const [isError, setIsError] = useState(false);

    const goHome = async () => {

        if (loginForm.valid) {
            setLoading(true);
            var isValidUser = await validateUser(loginForm);
            setLoading(false);
            if (isValidUser) {
                setIsError(false)
                navigate("/");
                setTimeout(() => {
                    endNav();
                }, 100);
            }
            else {
                setIsError(true)

            }
        }
    }

    return (
        <div className="login-background">

            <div id="login-container">
                <div className='login-header'>
                    <div className="signin-logo" onClick={() => goHome()}>
                        <MoveImage className="logo" src={logo} width={'66.4%'}></MoveImage>
                    </div>
                    {/* <div className="close-icon">
                        <SimpleLineIcon name={'close'}  onClick={() => goHome()} />  
                    </div> */}
                </div>
                    { isError && !loading && 
                        <div className="login-info">
                            <span>Username or password are incorrect.</span>
                        </div>
                    }

                <div className="text-field">
                    <div className="email">
                        <TextField
                            label={"Username"}
                            formControl={loginForm.get("username") as FormControl}
                            keyboardType={'text'}
                            onEnter={() => goHome()}
                        />
                    </div>
                    <div className="password">
                        <TextField
                            label={"Password"}
                            formControl={loginForm.get("password") as FormControl}
                            keyboardType={'password'}
                            onEnter={() => goHome()}
                        />
                    </div>
                </div>
                <div className="login-button">
                    <Button
                        text={'Login'}
                        loading={loading}
                        width={'77.3%'}
                        height={'7vh'}
                        // fontSize={'2rem'}
                        fontWeight={'700'}
                        disabled={disabled}
                        // formControl={loginForm.get("done")}
                        onClick={() => goHome()}
                    />
                </div>
            </div>
        </div>
    );
    // }
}

export default Login;