import React, { Component, useState, useEffect, useCallback } from 'react'
import List from 'screens/list/List';
import { getCard } from './kpiDetailData';


export interface KpiDetailProps {
    path?: string;
}

export interface KpiDetailState {
}

const KpiDetail = ({ path, ...props }: KpiDetailProps) => {

    //@ts-ignore
    let cardData = props.location.state.card.data;
    let kpiDetailCards: any = cardData.kpis.map((kpi: any) => getCard(kpi));
    kpiDetailCards = kpiDetailCards.flat();

    const handleCardClick = useCallback(
        i => {
            // alert("handle card click")
        },[])


    return (
        <>
            <List cards={kpiDetailCards} onCardClick={handleCardClick} />
        </>
    )
}

export default KpiDetail;
