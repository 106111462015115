import React from 'react'
import { IFormPage, IField } from "shared/model/form";
import { InputControlSelector } from 'components/inputControlSelector';


export interface FormSectionProps {
    formPage: IFormPage;
}

const FormSection = ({ formPage }: any) => {

    return (
      <div style={{ backgroundColor: '', width:'100%'}}>
        {formPage.fields.map((field: IField, key: any) => {
            if (Array.isArray(field)) {
              return (
                <div key={key}>
                  {
                    field.map((field, index) => {
                      return <div key={key} >
                        <InputControlSelector field={field}></InputControlSelector>
                      </div>
                    })
                  }
                </div>
              )
            } else if (typeof field === 'object') {
              return (
                <div key={key} >
                  <InputControlSelector field={field}></InputControlSelector>
                </div>
              )
            }
          })}
     </div>
    );
}

export default FormSection;