import React from "react";
import './Select.scss'

export interface SelectProps {
  label: string,
  icon?: string;
  selected?: boolean
  onBubbleSelect?(): void;
}

export default class Select extends React.PureComponent<SelectProps, any> {
  constructor(props: SelectProps) {
    super(props);
  }

  onBubbleSelect() {
    if (this.props.onBubbleSelect) {
      this.props.onBubbleSelect();
    }
  }

  public render() {
    return (
      <div className='movesize-container'>

        <div className="select-list" onClick={() => this.onBubbleSelect()} style={{backgroundColor: this.props.selected ? '#ECECEC' : 'white'}}>
        
        {this.props.icon && 
          <div className='list-icon'><img src={require(`assets/images/${this.props.icon}`)} /></div>
        }
          <div className='list-text'><span className="size-span">{this.props.label}</span></div>
        </div>
      </div>
    );
  }
}
