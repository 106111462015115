import React from 'react'
import "./KpiProgressBar.scss";

export interface KpiProgressBarProps {
    progress: number;
    activecolor?: any;
    remainingcolor?: any;
    style?: object;
    className?: any;
    leftlabel?: any;
    rightlabel?: any;
    labelbgcolor?: any;

}

export interface KpiProgressBarState {

}

class KpiProgressBar extends React.Component<KpiProgressBarProps, KpiProgressBarState> {
    constructor(props: KpiProgressBarProps) {
        super(props);
        this.state = {};
    }
    render() {
        let minlimit = 10;
        let minvalue = this.props.progress >= minlimit ? minlimit : this.props.progress;
        let skew = this.props.progress >= minlimit ? 'skewX(13deg)' : 'skewX(0deg);';
        let activeskew = this.props.progress >= minlimit ? 'skew(0deg, 0deg)' : 'skew(0deg, 0deg)';
        return (
            <div className="kpiprogress-container">
                <div className={`divstyle ${this.props.className}`}
                    style={{ backgroundColor: this.props.remainingcolor, ...this.props.style }}>
                    <div className="kpi-progress-active"
                        style={{
                            backgroundColor: this.props.activecolor, width: `${this.props.progress}%`,
                            transform: skew, ...this.props.style
                        }}>
                    </div>
                    <div className="active-skew"
                        style={{
                            backgroundColor: this.props.activecolor,
                            width: `${minvalue}%`, transform: activeskew
                            // width: minvalue,  transform: activeskew
                        }}>
                    </div>
                    <div className="label-left" style={{backgroundColor:this.props.labelbgcolor}}>
                   <div className="left-value">{this.props.leftlabel}</div>
                    </div>
                    <div className="label-right" style={{backgroundColor:this.props.labelbgcolor}}>
                    <div className="right-value">{this.props.rightlabel}</div>
                    </div>
                </div>
            </div>

        );
    }
}

export default KpiProgressBar;
