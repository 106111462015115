import React, { forwardRef } from "react";
import { useSpring, a, config } from 'react-spring'

export interface ImagePageProps {
    img: any;
    maxWidth: number;
    fh: any; 
    category: string; 
    title: string;
    text: string;
}

const ImagePage = ({ img: cover, maxWidth, fh, category, title, text }: ImagePageProps, ref: any) => {

    return (
        <a.div>
            <a.figure style={{ backgroundImage: `url(/imgs/${cover})`, height: fh }}>
                <div className="title" style={{ color: cover ? 'white' : 'black' }}>
                    <h3>{category}</h3>
                    <h2>{title}</h2>
                </div>
            </a.figure>
            <a.p style={{ width: maxWidth, y: fh.to((f: any) => f - 500) }}>{text}</a.p>
        </a.div>
    )
}

export default forwardRef(ImagePage);