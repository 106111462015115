import moment from 'moment';

export function getEstimatorDetails(branchId: any){
    console.dir("calling details method")
    const url = 'https://sparrowwebservices.allmysons.com/Branch.asmx/GetBranchDetailsPricingMobilePage?BranchID=' + branchId;
    fetch(url)
        .then((resp) => resp.text()) // Transform the data into json
        .then(function(data) {
            var resp=data.replace('<?xml version="1.0" encoding="utf-8"?>','')
            .replace('<string xmlns="http://tempuri.org/">','')
            .replace('</string>','');
            var dt = JSON.parse(resp);
            var zip = dt[0].zip;
            localStorage.setItem("zip", zip)
            localStorage.setItem("FirstName", dt[0].FirstName);
            localStorage.setItem("EmployeeImg", dt[0].EmployeeImg);
            console.dir(resp)
    })
}


export function saveLead(formObj: any) {
    console.dir(formObj);
    // read necessary values from formObject
    var movedate = formObj.MoveDate;
    
    // if not sure selected , add 15 days
    if(movedate=="")
    {
        formObj.MoveDate = moment().add(15,"days").format("MM/DD/YYYY");
        localStorage.setItem("notsure" , "1");
    
    }
    else{
        localStorage.setItem("notsure" , "0")
    }
   
    var Email = formObj.Email;
    if (Email == "") {
        Email = "Didnotprovide@allmysons.com";
    }
    debugger;
    var MoveDate = formObj.MoveDate;
    var FullName = formObj.FirstName;
    var Telephone = formObj.Telephone;
    Telephone = Telephone.replace('(', '').replace(')', '').replace(' ', '-').replace(' ', '-');
    var MoveSize = getMoveSizeFromTypeAndBedrooms(formObj.MoveSize, parseInt(formObj.MoveSizeDetail.toString())); // dbvalue;
  //  alert(MoveSize)
  var OriginZip = localStorage.getItem("zip");
    var MoveType = formObj.MoveType
    var Src = localStorage.getItem("Src");
    var Phone = Telephone;
    var formData = {
        FullName: FullName,
        Telephone: Phone,
        Email: Email,
        MoveDate: formObj.MoveDate,
        MoveSize: MoveSize,
        OriginZip: OriginZip,
        MoveType: MoveType,
        Src: Src,
    }
    submitQuoteRequest(formData.FullName, formData.Telephone, formData.Email, formData.MoveDate, formData.MoveSize, formData.OriginZip, "", formData.MoveType)
}

const getMoveSizeFromTypeAndBedrooms = (moveType: any, bedrooms: number) => {
    	//Accepts a string and an int.
		
		switch(moveType) {
			case "House":
			  // code block
				if(bedrooms >= 4){
					return "House 4 Bedroom or more";
				} else if(isNaN(bedrooms)){return "House 2 Bedroom"
				} else {return "House " + bedrooms + " Bedroom"}
			case "Apartment":
			// code block
				if(bedrooms >= 2){
					return "Apt 2 Bedroom or more";
				} else if(isNaN(bedrooms)) {return "Apt 2 Bedroom or more"
				} else {return "Apt" + bedrooms + " Bedroom"}
			case "Condo":
				return "Condo";
			default:
				return "Other";
		}

}

// METHOD TO BIND VALUES TO API CALL
const submitQuoteRequest = (name: any, phone: any, email: any, date: any, size: any, origin: any, destination: any, type: any) => {
    var notsure = false;
    if (localStorage.getItem("notsure") === "1") {
        notsure = true;
    } else {
        notsure = false;
    }
    var Src = localStorage.getItem("Src");
    console.log(name);
    var temp = name.split(" ");
    var firstName = temp.shift();
    console.log(temp);
    var lastName = temp.join(" ");
    console.log(lastName);
    console.log("calling service");

    if (size === "House 4 Bedroom or more") {

        //this.callService("https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMP02", "FirstName=" + firstName
        callService("http://apistaging.allmysons.com/amsleadimport.asmx/ImportLeadPMP02", "FirstName=" + firstName
            + "&LastName=" + lastName
            + "&Telephone=" + phone
            + "&Email=" + email
            + "&MoveDate=" + date
            + "&MoveSize=" + size
            + "&OriginZip=" + origin
            + "&Src="
            + Src
            + "&DestinationZip=&APIReferenceID=1&CanWeText=true&MovingDateNotSure=" + notsure
            + "&ZipCodeNotSure=false&MoveType="
            + type
            + "&IsAutodialer=false"
            //@ts-ignore
            , "", this);
    }
    else {
        //  callService("https://api.allmysons.com/amsleadimport.asmx/ImportLeadPMP02",

        callService("http://apistaging.allmysons.com/amsleadimport.asmx/ImportLeadPMP02",
            "FirstName=" + firstName
            + "&LastName=" + lastName
            + "&Telephone=" + phone
            + "&Email=" + email
            + "&MoveDate=" + date
            + "&MoveSize=" + size
            + "&OriginZip=" + origin
            + "&Src="
            + Src
            + "&DestinationZip=&APIReferenceID=1&CanWeText=true&MovingDateNotSure=" + notsure
            + "&ZipCodeNotSure=false&MoveType="
            + type
            + "&IsAutodialer=true"
            //@ts-ignore
            , "", this);
    }

}

// METHOD TO BUILD XMLHttpRequest
const callService = (url: any, params: any, callback: any, scope: any) => {
    var http = new XMLHttpRequest();
    http.open("POST", url, true);
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    console.log("triggering call of service");
    console.log(url);
    console.log(params);
    http.onreadystatechange = function () {
        if (http.readyState === 4 && http.status === 200) {
            console.log("firing callback")
           // callback(http.responseText, scope);
           console.dir(http.responseText);
           localStorage.removeItem('movewith')
           localStorage.removeItem('notsure')
           
        }
    }
    http.send(params);
}

export async function fetchGraphQL(method:string, params: string) {
    var jsonData=null;
    var endPoint='http://graphqlsampleapi-test.us-west-2.elasticbeanstalk.com/graphql';
    try {
        const fetchResult = fetch(endPoint, {
            method: method.toLocaleUpperCase(),
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query: params }),
        });

        const response = await fetchResult;
        console.log("response:"+JSON.stringify(response));

        if (response.ok) {
            jsonData = await response.json();
            console.log(jsonData);
        } else {
            jsonData=null;
            throw new Error(response.statusText);
        }
        return jsonData;
    } 
    catch(err){
        //network related errors
        console.error("fetchGraphQL:"+err);
        throw err;
      }
  }

  export async function validateUser(loginForm: any){
    try {
        var params = `query{
            validateUser(UserID:"`+ loginForm.value.username + `",Password:"` + loginForm.value.password + `"){
                isValid
                employeeID
                employeeLoginID
                userType
                employeeName
                branchName
                branchID
            }
        }`;
        console.log(params);
        
        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
        localStorage.removeItem("branch")
        localStorage.removeItem("employeeName")
        localStorage.removeItem("branchId")
        if (response.data.validateUser[0].isValid === "1") {
            localStorage.setItem("branchName", response.data.validateUser[0].branchName)
            localStorage.setItem("employeeName", response.data.validateUser[0].employeeName)
            localStorage.setItem("branchId", response.data.validateUser[0].branchID)
          localStorage.setItem("branchId", "9")
          opsKpidata(9);
          qualityKpidata(9)
          //  console.dir(response.data);
            return response.data;

            return true;
          } else {
            //alert("Invalid credential.");        
          }
            return false;
    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

  }

   export async function opsKpidata(branchId: any){
    try {
        var params = `query{
            opsKpiSummary(BranchID:"`+branchId+`"){
             row
             label
             yoy
             target
             currentYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             lastYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             leaderBoard{
               name
               color
               code
               score
             }
           }
           }`;
        console.log(params);
        
        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
      console.log(response.data.opsKpiSummary);
      localStorage.setItem("opskpi",JSON.stringify(response.data.opsKpiSummary));
      return response.data.opsKpiSummary;

    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

  } 

  export async function qualityKpidata(branchId: any){
    try {
        var params = `query{
            qualityKpiSummary(BranchID:"`+branchId+`"){
             row
             label
             yoy
             target
             currentYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             lastYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             leaderBoard{
               name
               color
               code
               score
             }
           }
           }`;
        console.log(params);
        
        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
      console.log(response.data.qualityKpiSummary);
      localStorage.setItem("qualityKpi",JSON.stringify(response.data.qualityKpiSummary));
      return response.data.qualityKpiSummary;

    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

  } 
 
